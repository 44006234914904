<template>
  <div class="Awarp">
    <dashCard
        class="photovoltaicBox11"
        :operateWidth="operateWidth"
        :isLoading="isLoading"
        :gridOption="gridOption"
        @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('chuneng4_realtimeDataBMS','储能BMS实时数据', getZEdata)}}</template>
      <template slot="aside">
        <a-select
            v-model="searchObj['ITEM']"
            style="width: 220px"
            @change="handleChange"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
              v-for="(item, key) in optionsData"
              :key="key"
              :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </template>
      <div class="content-warp">
        <div class="content-1st">
          <div class="product-pre1">
            <div>
              <img src="@/assets/imgs/echarts/电池.svg" />
            </div>
          </div>
          <div class="content-1st-right">
            <div class="content-1st-right-row">
              <div>
                <font>{{commonLangHandler('chuneng4_status','电池系统状态', getZEdata)}}</font>
                <span class="color1">{{ topData.电池系统状态 }}</span>
              </div>
              <div>
                <font>{{commonLangHandler('chuneng4_numbers','循环次数', getZEdata)}}</font>
                <span>{{ topData.循环次数 }}</span>
              </div>
            </div>
            <div class="content-1st-right-row">
              <div style="word-break: break-all">
                <font>SOC</font>
                <span class="color2">{{
                    topData.SOC + (topData.SOC ? "%" : "")
                  }}</span>
              </div>
              <div style="word-break: break-all">
                <font>SOH</font>
                <span>{{ topData.SOH + (topData.SOH ? "%" : "") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-2nd">
          <div class="content-2nd-list">
            <div class="content-2nd-list-item">
              <span class="list-item-label">{{commonLangHandler('chuneng11_totalVoltage','总电压(V)', getZEdata)}}</span>
              <div class="list-item-progress" style="width: 118px">
                <span
                  :class="'status' + '1'"
                  :style="{
                    width:
                    perceCalc(topData.电池系统电压.valuemin,topData.电池系统电压.maxValue,topData.电池系统电压.value)
                      +'%',
                  }"
                ></span>
              </div>
              <span class="list-item-value">{{
                topData.电池系统电压.value
              }}</span>
            </div>
          </div>
          <div class="content-2nd-list">
            <div class="content-2nd-list-item">
              <span class="list-item-label">{{commonLangHandler('photovoltaicBox14_current','电流(A)', getZEdata)}}</span>
              <div class="list-item-progress" style="width: 118px">
                <span
                  :class="'status' + '1'"
                  :style="{
                    width:
                    perceCalc(topData.电池系统电流.valuemin,topData.电池系统电流.maxValue,topData.电池系统电流.value)
                      +'%',
                  }"
                ></span>
              </div>
              <span class="list-item-value">{{
                topData.电池系统电流.value
              }}</span>
            </div>
          </div>
          <div class="content-2nd-list">
            <div class="content-2nd-list-item">
              <span class="list-item-label">DOD(%)</span>
              <div class="list-item-progress" style="width: 118px">
                <span
                  :class="'status' + '1'"
                  :style="{
                    width: perceCalc(topData.DOD.valuemin,topData.DOD.maxValue,topData.DOD.value)
                      +'%',
                  }"
                ></span>
              </div>
              <span class="list-item-value">{{ topData.DOD.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </dashCard>
    <a-modal
        v-if="!isModal"
        class="Amodal"
        v-model="option.visible"
        :closable="false"
        :footer="null"
        :keyboard="false"
        width="100%"
        :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <photovoltaicBox11
            :option="option"
            :isModal="true"
            :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import Img005 from "@/assets/imgs/add/product5.png";
export default {
  name: "photovoltaicBox11",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      itemList: [
        {
          code: "逆变器编号：1AA1",
          name: "逆变器编号：1AA1",
        },
      ],
      topData: {
        电池系统状态: "",
        循环次数: "",
        SOC: "",
        SOH: "",
        电池系统电压: {
          maxValue: "",
          value: "",
        },
        电池系统电流: {
          maxValue: "",
          value: "",
        },
        DOD: {
          maxValue: "",
          value: "",
        },
      },
      searchObj: {
        ITEM: "", //选中的项目
      },
      list1: {},
      chartOption1: {},
      detailInfo: {},
      colorList: ["#1191E6", "#7ED6C6", "#1E6119", "#160C9C"],
      optionsData: [],
    };
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    perceCalc(min,max,num){
            let perce = 0;
            if(isNaN(min)||isNaN(max)||isNaN(num)){
              perce = 0
            }else{
              if((Number(max)-Number(min)) == 0 || (Number(num)-Number(min)) == 0){
                perce = 0
              }else{
                perce = (Number(num)-Number(min))/(Number(max)-Number(min))
              }
            }
            console.log(perce,'perceperceperceperce')
            if(perce < 0 || perce == 0){
              return 0
            }else if(perce > 1 || perce == 1){
              return 100
            }else{
              return perce*100
            }
          },
    getList() {
      this.$axios({
        url:  Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type: "储能BMS",
        },
      }).then((res) => {
        //console.log(res);
        let arr = [];
        res.data.data.forEach((item) => {
          let obj = {
            value: item.assetNumber,
            label: item.assetName,
          };
          arr.push(obj);
          this.optionsData.push(obj);
          if (this.optionsData.length !== 0) {
            this.searchObj.ITEM = this.optionsData[0].value;
          }
        });
        this.getData();
      });
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "今日",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "本周",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "本月",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "全年",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/battery/bms/realtimevalue",
        method: "post",
        data: {
          assetNumber: this.searchObj.ITEM,
        },
      })
          .then((res) => {
            //console.log(res);
            this.list1 = res.data.data;

            for (let key in this.list1) {
              let obj=this.list1[key]

              if (key === "operationState") {
                this.topData.电池系统状态 = obj.value;
              } else if (key === "cycleNumber") {
                this.topData.循环次数 = obj.value;
              } else if (key === "SOC") {
                this.topData.SOC = obj.value;
              } else if (key === "SOH") {
                this.topData.SOH = obj.value;
              } else if (key === "voltages") {
                this.topData.电池系统电压 = obj;
              } else if (key === "current") {
                this.topData.电池系统电流 = obj;
              } else if (key === "DOD") {
                this.topData.DOD = obj;
              }

            }


          })
          .catch((wrong) => {})
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
  mounted() {
    this.getList();
    this.getOperateWidth();
    if (!this.option.visible) {
      // this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.photovoltaicBox11 {
  .content-warp {
    display: flex;
    height: inherit;
    flex-direction: column;
    .content-1st {
      display: flex;
      height: 200px;
      justify-content: space-between;
      .product-pre1 {
        width: 240px;
        // height: inherit;
        margin-right: 66px;
        margin-left: 5px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        img {
          width: 80%;
          height: auto;
          margin-left: 10px;
        }
      }
      .content-1st-right {
        flex: 1;
        display: flex;
        // margin-top: 20px;
        flex-direction: column;
        .content-1st-right-row {
          display: flex;
          justify-content: flex-start;
          &:nth-child(1) {
            margin-bottom: 30px;
          }
          div {
            display: flex;
            flex-direction: column;
            width: 182px;
            height: 78px;
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
            span {
              margin-top: 6px;
              font-size: 28px;
              font-weight: 600;
              color: #1f1f1f;
              line-height: 48px;
              font {
                font-size: 12px;
                font-weight: 400;
                color: #9f9f9f;
                line-height: 20px;
                margin-left: 6px;
              }
            }
          }
          .color1 {
            color: #3366ff;
          }
          .color2 {
            color: #51a34b;
          }
        }
      }
    }
    .content-2nd {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .content-2nd-list {
        flex: 1;
        .content-2nd-list-item {
          height: 40px;
          box-sizing: border-box;
          padding: 10px 16px;
          display: flex;
          align-items: center;
          .list-item-label {
            width: 70px;
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 20px;
          }
          .list-item-progress {
            margin-right: 10px;
            //flex: 1;
            height: 7px;
            background: #dbdbdb;
            span {
              display: block;
              height: inherit;
              width: 0%;
            }
          }
          .list-item-value {
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 20px;
          }
        }
        .status1 {
          background: #25b131;
        }
        .status2 {
          background: #ff7b10;
        }
        .status3 {
          background: #f1404f;
        }
      }
    }
  }
}
</style>
